<template>
  <v-container style="max-width: 1160px" class="d-flex flex-column fill-height">
    <v-card
      tile
      flat
      class="pa-4 elevation-0 overflow-y-auto"
      height="calc(89vh - 80px)"
      width="100%"
    >
      <v-card-title class="pa-0 mb-2 mt-10 font-weight-bold">
        {{ $t("offboardingUser.selectTheUser") }}
      </v-card-title>
      <v-autocomplete
        class="mt-3"
        outlined
        with="200"
        style="width: 423px"
        v-model="payload.email"
        :items="usersNonAdmin"
        item-text="email"
        item-value="email"
        :label="$t('common.user')"
      />

      <v-card-title class="pa-0 mb-2 mt-5 font-weight-bold">
        {{ $t("offboardingUser.suspendTheUser") }}
      </v-card-title>

      <div
        class="mb-2 grey--text text--darken-2 text-justify"
        style="max-width: 800px"
      >
        {{ $t("offboardingUser.suspendTheUserCheckboxLabel") }}
      </div>

      <div class="d-flex mb-2">
        <v-checkbox
          class="mt-0"
          v-model="payload.suspend"
          color="accent"
          :label="$t('offboardingUser.suspend_user')"
        />
      </div>

      <v-card-title class="pa-0 mb-2 mt-5 font-weight-bold">
        {{ $t("offboardingUser.changePassword") }}

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on" class="ml-3">
              mdi-shield-alert
            </v-icon>
          </template>
          <span> {{ $t("offboardingUser.update_password") }} </span>
        </v-tooltip>
      </v-card-title>
      <div class="mb-2 grey--text text--darken-2">
        {{ $t("offboardingUser.changePasswordDescription") }}
      </div>

      <v-text-field
        class="mt-4"
        outlined
        with="200"
        style="width: 423px"
        v-model="payload.password"
        :label="$t('common.password')"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :rules="passwordRules"
      />

      <v-card-title class="pa-0 mb-2 mt-5 font-weight-bold">
        {{ $t("offboardingUser.conecta_control_shifts") }}
        <v-tooltip top>
          <template #activator="{ on: onTooltip }">
            <img
              v-on="!hasControlPlan && onTooltip"
              class="ml-6"
              :src="conecta_control_logo"
              alt="Conecta Control"
              height="30"
              @click="handleRemoveUserFromShifts"
            />
          </template>
          {{ $t("offboardingUser.companyNoHasConectaControl") }}
        </v-tooltip>
      </v-card-title>

      <div
        class="mb-2 grey--text text--darken-2 text-justify"
        style="max-width: 800px"
      >
        {{ $t("offboardingUser.remove_user_shifts") }}
      </div>

      <div class="d-flex mt-3">
        <v-checkbox
          class="mt-0"
          v-model="payload.remove_from_control_shifts"
          style="width: 1px !important"
          color="accent"
          :disabled="!hasControlPlan"
        />
        <v-tooltip top>
          <template #activator="{ on: onTooltip }">
            <div v-on="!hasControlPlan && onTooltip" class="mt-1">
              <span
                :class="`ml-8 grey--text ${
                  !hasControlPlan ? 'text--darken-1' : 'text--darken-2'
                } mt-1`"
                style="max-width: 1000px"
                @click="handleRemoveUserFromShifts"
              >
                {{ $t("offboardingUser.removeFromControlShifts") }}
              </span>
            </div>
          </template>
          {{ $t("offboardingUser.companyNoHasConectaControl") }}
        </v-tooltip>
      </div>
    </v-card>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3 justify-space-between"
      width="100%"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="$emit('back')"
        :disabled="loading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="suspendUser"
        :loading="loading"
        :disabled="disabledNextButton"
      >
        {{ $t("action.next") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "UserSelection",
  data() {
    return {
      conecta_control_logo: require("@/assets/appslogos/full/conecta_control_full.png"),
      loading: false,
      showPassword: false,
      payload: {
        suspend: false,
        remove_from_control_shifts: false,
        password: undefined,
        email: undefined,
      },
      passwordRules: [
        (value) =>
          (value && value.length >= 8 && value.length <= 100) ||
          !value ||
          this.$t("offboardingUser.passwordRuleMessage"),
      ],
    };
  },
  computed: {
    ...mapGetters(["users", "token", "companyHasControl"]),

    usersNonAdmin() {
      return this.users.filter(
        (user) => !user.is_google_admin && !user.is_suspended
      );
    },
    passwordEntered() {
      return !!this.payload.password && this.payload.password.length > 0;
    },

    hasRequestFields() {
      return (
        this.payload.email &&
        (this.passwordEntered ||
          this.payload.suspend ||
          this.payload.remove_from_control_shifts)
      );
    },

    disabledNextButton() {
      return (
        !this.payload.email ||
        this.loading ||
        (this.passwordEntered && this.payload.password.length < 8) ||
        (this.passwordEntered && this.payload.password.length > 100)
      );
    },

    hasControlPlan() {
      const invalid_status = [
        "PLAN_TRIAL_ENDED",
        "PRODUCT_DOES_NOT_EXIST_IN_PLANS",
      ];

      return !invalid_status.includes(this.companyHasControl);
    },
  },

  methods: {
    ...mapMutations([
      "setSnackBar",
      "setOffboardingStep",
      "setUserOffboardSelected",
    ]),

    handleRemoveUserFromShifts() {
      if (this.hasControlPlan) {
        this.payload.remove_from_control_shifts =
          !this.payload.remove_from_control_shifts;
      }
    },
    async suspendUser() {
      this.setUserOffboardSelected(
        this.users.find((user) => user.email === this.payload.email)
      );
      if (!this.hasRequestFields) {
        this.$emit("next");
        return;
      }
      this.loading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/suspend-google-user`;

      const auth = {
        headers: { Authorization: this.token },
      };

      await this.$axios
        .patch(url, this.payload, auth)
        .then(() => {
          this.setSnackBar({
            message: this.$t("offboardingUser.actionsExecuted"),
            show: true,
            color: "success",
          });
          this.loading = false;

          this.$emit("next");
        })
        .catch((err) => {
          const errorCode = err.response.data.code;
          this.setSnackBar({
            message: this.$t(`errorMessages.${errorCode}`),
            show: true,
            color: "error",
          });
          this.loading = false;
        });
    },
  },

  watch: {
    "payload.password"(value) {
      this.payload.password = value ? value : undefined;
    },
  },
};
</script>
